import {
  SET_GOLD,
  SET_WEEKS,
  SET_CHARISMA_BONUS,
  SET_BUY_RESULT,
  UPDATE_BONUS,
} from '@/components/DowntimeBuyMagicItems/store/action-types'
import DEFAULT_STATE from '@/components/DowntimeBuyMagicItems/store/default_state'

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_BUY_RESULT: {
      return {
        ...state,
        bonusFixed: action.bonusFixed,
        checkResult: action.checkResult,
        magicItemInfoList: action.magicItemInfoList,
        roll: action.roll,
        showResult: true,
      }
    }

    case UPDATE_BONUS: {
      return {
        ...state,
        bonus: action.bonus,
        magicItemInfoList: [],
        showResult: false,
      }
    }

    case SET_CHARISMA_BONUS: {
      return {
        ...state,
        charismaBonus: action.charismaBonus,
      }
    }

    case SET_WEEKS: {
      return {
        ...state,
        weeks: action.weeks,
      }
    }

    case SET_GOLD: {
      return {
        ...state,
        gold: action.gold,
      }
    }

    default:
      return state
  }
}
