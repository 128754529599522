import React from 'react'

import Price from '@/components/Price'

import './MagicItemBuyFormStyles.less'

const MagicItemBuyFormComponent = (
  {
    bonus,
    charismaBonus,
    charismaBonusText,
    generate,
    gold,
    onChangeCharismaBonus,
    onChangeGold,
    onChangeWeeks,
    weeks,
  }
) => (
  <form
    className='MagicItemBuyForm'
    onSubmit={generate}
  >
    <header className='MagicItemBuyForm_header'>Настройки поиска</header>

    <div className='MagicItemBuyForm_controls'>
      <fieldset>
        <label className='MagicItemBuyForm_row'>
          <header>Бонус Харизмы (Убеждение) <b>{charismaBonusText}</b></header>
          <input
            max="20"
            min="-5"
            onChange={onChangeCharismaBonus}
            type="range"
            value={charismaBonus}
          />
        </label>
      </fieldset>

      <fieldset>
        <header>Бонусы от затрат времени и золота <i>(максимум +10)</i></header>
        <label className='MagicItemBuyForm_row'>
          <span>Траты золота <Price price={gold * 100}/></span>
          <input
            max="1100"
            min="100"
            onChange={onChangeGold}
            placeholder="Траты золота"
            step="100"
            type="range"
            value={gold}
          />
        </label>
        <label
          className='MagicItemBuyForm_row'
          title='Одна рабочая неделя — 5 дней'
        >
          <span>Рабочих недель поиска {weeks}</span>
          <input
            max="11"
            min="1"
            onChange={onChangeWeeks}
            placeholder="Рабочих недель"
            type="range"
            value={weeks}
          />
        </label>

        <div className='MagicItemBuyForm_row'>
          <span>
            Получаемый бонус <b>+{bonus}</b>
          </span>
        </div>

      </fieldset>
    </div>

    <button className='MagicItemBuyForm_btn'>
      Искать магические предметы!
    </button>
  </form>
)

export default MagicItemBuyFormComponent
