import React from 'react'
import { Link } from "gatsby"
import { connect } from 'react-redux'

import BooleanMark from '@/components/BooleanMark'
import Price from '@/components/Price'

import {magicItemCollection} from '@/constants/magicItemList'
import {magicItemRarityCollection} from '@/constants/magicItemRarityList'

import generateMagicItemPageUrlById from '@/utils/generateMagicItemPageUrlById'

import './MagicItemBuyListStyles.less'

const MagicItemBuyListComponent = ({magicItemInfoList}) => (
  <section className='MagicItemBuyList'>
    <header className='MagicItemBuyList_header'>Найденные предметы</header>
    <table className='MagicItemBuyList_table'>
      <thead>
        <tr>
          <th>Название</th>
          <th>Редкость</th>
          <th>Расходник?</th>
          <th>Цена</th>
          <th title='Вероятность 10%'>Осложнения при покупке</th>
        </tr>
      </thead>
      <tbody>
        {
          magicItemInfoList.map(
            ({ magicItemId, problem, price }, i) => {
              const { name, isConsumable, rarity, genderId } = magicItemCollection[magicItemId]
              const rarityText = magicItemRarityCollection[rarity].nameByGender[genderId]

              return (
                <tr key={`${magicItemId}_${i}`}>
                  <td>
                    <Link to={generateMagicItemPageUrlById(magicItemId)}>
                      {name}
                    </Link>
                  </td>
                  <td>{rarityText}</td>
                  <td className='MagicItemBuyList_isConsumable'><BooleanMark val={isConsumable}/></td>
                  <td className='MagicItemBuyList_price'><Price price={price * 100}/></td>
                  <td>{problem}</td>
                </tr>
              )
            }
          )
        }
      </tbody>
    </table>
  </section>
)

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    magicItemInfoList: state.magicItemInfoList,
  })
)(MagicItemBuyListComponent)
