export default {
  bonus: 0,
  bonusFixed: 0,
  charismaBonus: 0,
  gold: 100,
  magicItemInfoList : [],
  roll: null,
  showResult: false,
  weeks: 1,
}
