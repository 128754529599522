import sample from 'lodash/sample'
import random from 'lodash/random'
import React from 'react'

import magicItemList from '@/constants/magicItemList'

import findRules from './../constants/findRules'

import generateBuyProblem from './generateBuyProblem'
import generatePrice from './generatePrice'

const magicItemListWithoutTemplates = magicItemList.filter(({isTemplate}) => !isTemplate)

const generateMagicItems = check => {
  const result = []

  if (check > 0) {
    const { rules, itemMax } = findRules.find(
      ({bonusMin, bonusMax}) => (bonusMin <= check && check <= bonusMax)
    )

    let countToPick = random(1, itemMax)

    while (countToPick) {
      let randomSeed = random(1, 100)

      const chosenRule = rules.reduce(
        (prevRuleItem, currentRuleItem) => {
          if ((randomSeed - prevRuleItem.weight) >= 0) {
            randomSeed = randomSeed - prevRuleItem.weight
            // идём дальше
            return currentRuleItem
          } else {
            return prevRuleItem
          }
        }
      )

      const magicItemListFiltered = magicItemListWithoutTemplates.filter(
        ({rarity, isConsumable}) => rarity === chosenRule.rarity && isConsumable === chosenRule.isConsumable
      )

      const {isConsumable, id: magicItemId, rarity} = sample(magicItemListFiltered)
      const problem = generateBuyProblem()
      const price = generatePrice(rarity, isConsumable)

      result.push({
        magicItemId,
        problem,
        price,
      })

      countToPick--
    }
  }

  return result
}

export default generateMagicItems
