import { UPDATE_BONUS } from '@/components/DowntimeBuyMagicItems/store/action-types'

import store from '@/components/DowntimeBuyMagicItems/store'

const BONUS_MAX = 10
const GOLD_STEP = 100
const WEEK_STEP = 1

export default () => {
  const {gold, weeks} = store.getState()

  const bonusCalculated = ((gold - GOLD_STEP) / GOLD_STEP) + ((weeks - WEEK_STEP) / WEEK_STEP)
  const bonus = Math.min(bonusCalculated, BONUS_MAX)

  return {
    type: UPDATE_BONUS,
    bonus,
  }
}
