import sample from 'lodash/sample'

const PROBLEM_PROBABILITY = 0.1

const problemVariantList = [
  'Предмет — подделка, подкинутая недоброжелателем.',
  'Предмет украден врагами группы.',
  'Предмет проклят богом.',
  'Первоначальный владелец предмета готов убить, чтобы вернуть его, а противники группы распространяют новости о его продаже.',
  'Предмет является ключевым элементом тёмного пророчества.',
  'Перед сделкой продавец был убит.',
  'Продавец — это дьявол, желающий заключить сделку.',
  'Предмет служит ключом к освобождению злой сущности.',
  'Третья сторона также хочет купить предмет и готова заплатить двойную стоимость.',
  'Предмет является порабощённым разумным существом.',
  'Предмет связан с культом.',
  'Враги группы распространяют слухи о том, что предмет — артефакт злых сил.',
]

const generateBuyProblem = () => Math.random() <= PROBLEM_PROBABILITY
  ? sample(problemVariantList)
  : null

export default generateBuyProblem
