import React from 'react'
import { Provider} from 'react-redux'

import store from './store'
import DowntimeBuyMagicItemsComponent from './DowntimeBuyMagicItemsComponent'

export default () => (
  <Provider store={store}>
    <DowntimeBuyMagicItemsComponent/>
  </Provider>
)
