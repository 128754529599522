import {
  MGC_RARITY_COMMON,
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
} from '@/constants/magicItemRarityList'

export default [
  {
    comment: 'Таблица А — A — Незначительные предметы (встречаются в 4 раза чаще)',
    bonusMin: 1,
    bonusMax: 5,
    itemMax: 6,
    rules: [
      { // 45% Обычн
        rarity: MGC_RARITY_COMMON,
        isConsumable: false,
        weight: 45,
      },
      { // 45% Обычн, расходник
        rarity: MGC_RARITY_COMMON,
        isConsumable: true,
        weight: 45,
      },
      { // 5% Необычн
        rarity: MGC_RARITY_UNCOMMON,
        isConsumable: false,
        weight: 5,
      },
      { // 5% Необычн, расходник
        rarity: MGC_RARITY_UNCOMMON,
        isConsumable: true,
        weight: 5,
      },
    ],
  },
  {
    comment: 'Таблица Б — B — Незначительные предметы (встречаются в 4 раза чаще)',
    bonusMin: 6,
    bonusMax: 10,
    itemMax: 4,
    rules: [
      { // 80% Необычн, расходник
        rarity: MGC_RARITY_UNCOMMON,
        isConsumable: true,
        weight: 80,
      },
      { // 5% Обычн
        rarity: MGC_RARITY_COMMON,
        isConsumable: false,
        weight: 5,
      },
      { // 5% Обычн, расходник
        rarity: MGC_RARITY_COMMON,
        isConsumable: true,
        weight: 5,
      },
      { // 5% Редк
        rarity: MGC_RARITY_RARE,
        isConsumable: false,
        weight: 5,
      },
      { // 5% Редк, расходник
        rarity: MGC_RARITY_RARE,
        isConsumable: true,
        weight: 5,
      },
    ],
  },
  {
    comment: 'Таблица В — C — Незначительные предметы (встречаются в 4 раза чаще)',
    bonusMin: 11,
    bonusMax: 15,
    itemMax: 4,
    rules: [
      { // 80% Редк, расходник
        rarity: MGC_RARITY_RARE,
        isConsumable: true,
        weight: 80,
      },
      { // 5% Необычн
        rarity: MGC_RARITY_UNCOMMON,
        isConsumable: false,
        weight: 5,
      },
      { // 5% Необычн, расходник
        rarity: MGC_RARITY_UNCOMMON,
        isConsumable: true,
        weight: 5,
      },
      { // 5% Очень редк
        rarity: MGC_RARITY_VERY_RARE,
        isConsumable: false,
        weight: 5,
      },
      { // 5% Очень редк, расходник
        rarity: MGC_RARITY_VERY_RARE,
        isConsumable: true,
        weight: 5,
      },
    ],
  },
  {
    comment: 'Таблица Г — D — Незначительные предметы (встречаются в 4 раза чаще)',
    bonusMin: 16,
    bonusMax: 20,
    itemMax: 4,
    rules: [
      { // 80% Очень редк, расходник
        rarity: MGC_RARITY_VERY_RARE,
        isConsumable: true,
        weight: 80,
      },
      { // 5% Легендарн
        rarity: MGC_RARITY_LEGENDARY,
        isConsumable: false,
        weight: 5,
      },
      { // 5% Легендарн, расходник
        rarity: MGC_RARITY_LEGENDARY,
        isConsumable: true,
        weight: 5,
      },
      { // 5% Редк
        rarity: MGC_RARITY_RARE,
        isConsumable: false,
        weight: 5,
      },
      { // 5% Редк, расходник
        rarity: MGC_RARITY_RARE,
        isConsumable: true,
        weight: 5,
      },
    ],
  },
  {
    comment: 'Таблица Д — E — Незначительные предметы (встречаются в 4 раза чаще)',
    bonusMin: 21,
    bonusMax: 25,
    itemMax: 4,
    rules: [
      { // 50% Легендарн, расходник
        rarity: MGC_RARITY_LEGENDARY,
        isConsumable: true,
        weight: 50,
      },
      { // 10% Очень редк
        rarity: MGC_RARITY_VERY_RARE,
        isConsumable: false,
        weight: 10,
      },
      { // 40% Очень редк, расходник
        rarity: MGC_RARITY_VERY_RARE,
        isConsumable: true,
        weight: 40,
      },
    ],
  },
  {
    comment: 'Таблица Е — F — Значимые предметы',
    bonusMin: 26,
    bonusMax: 30,
    itemMax: 4,
    rules: [
      { // 70% Необычн
        rarity: MGC_RARITY_UNCOMMON,
        isConsumable: false,
        weight: 70,
      },
      { // 10% Необычн, расходник
        rarity: MGC_RARITY_UNCOMMON,
        isConsumable: true,
        weight: 10,
      },
      { // 10% Обычн
        rarity: MGC_RARITY_COMMON,
        isConsumable: false,
        weight: 10,
      },
      { // 10% Редк
        rarity: MGC_RARITY_RARE,
        isConsumable: false,
        weight: 10,
      },
    ],
  },
  {
    comment: 'Таблица Ё — G — Значимые предметы',
    bonusMin: 31,
    bonusMax: 35,
    itemMax: 4,
    rules: [
      { // 70% Редк
        rarity: MGC_RARITY_RARE,
        isConsumable: false,
        weight: 70,
      },
      { // 10% Редк, расходник
        rarity: MGC_RARITY_RARE,
        isConsumable: true,
        weight: 10,
      },
      { // 10% Необычн
        rarity: MGC_RARITY_UNCOMMON,
        isConsumable: false,
        weight: 10,
      },
      { // 10% Очень редк
        rarity: MGC_RARITY_VERY_RARE,
        isConsumable: false,
        weight: 10,
      },
    ],
  },
  {
    comment: 'Таблица Ж — H — Значимые предметы',
    bonusMin: 36,
    bonusMax: 40,
    itemMax: 4,
    rules: [
      { // 70% Очень редк
        rarity: MGC_RARITY_VERY_RARE,
        isConsumable: false,
        weight: 70,
      },
      { // 10% Очень редк, расходник
        rarity: MGC_RARITY_VERY_RARE,
        isConsumable: true,
        weight: 10,
      },
      { // 10% Легендарн
        rarity: MGC_RARITY_LEGENDARY,
        isConsumable: false,
        weight: 10,
      },
      { // 10% Редк
        rarity: MGC_RARITY_RARE,
        isConsumable: false,
        weight: 10,
      },
    ],
  },
  {
    comment: 'Таблица З — I — Значимые предметы',
    bonusMin: 41,
    bonusMax: Infinity,
    itemMax: 4,
    rules: [
      { // 70% Легендарн
        rarity: MGC_RARITY_LEGENDARY,
        isConsumable: false,
        weight: 70,
      },
      { // 10% Легендарн, расходник
        rarity: MGC_RARITY_LEGENDARY,
        isConsumable: true,
        weight: 10,
      },
      { // 20% Очень редк
        rarity: MGC_RARITY_VERY_RARE,
        isConsumable: false,
        weight: 20,
      },
    ],
  },
]
