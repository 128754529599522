import React from 'react'

import Layout from '@/components/Layout'
import Seo from '@/components/Seo'
import DowntimeBuyMagicItems from '@/components/DowntimeBuyMagicItems'

export default () => (
  <Layout>
    <Seo title='Покупка магических предметов во время простоя' />
    <DowntimeBuyMagicItems/>
  </Layout>
)
