import React from 'react'
import { connect } from 'react-redux'

import PageTitle from '@/components/PageTitle'
import Price from '@/components/Price'
import SourceInfo from '@/components/SourceInfo'

import {SOURCE_XGTE} from '@/constants/sourceList'

import formatBonus from '@/utils/formatBonus'

import MagicItemBuyList from './components/MagicItemBuyList'
import MagicItemBuyForm from './components/MagicItemBuyForm'

import './DowntimeBuyMagicItemsStyles.less'

const DowntimeBuyMagicItemsComponent = (
  {
    bonusFixed,
    charismaBonus,
    checkResult,
    roll,
    showResult,
  }
) => {
    const charismaBonusText = formatBonus(charismaBonus, true)

    return (
      <section className='DowntimeBuyMagicItems'>
          <PageTitle>Покупка магических предметов</PageTitle>
          <p>Генерация результатов деятельности во время простоя «Покупка магических предметов» на основе «<SourceInfo source={SOURCE_XGTE} useFullName/>».</p>

          <p>Чтобы приобрести магический предмет, нужно найти людей, желающих его продать, и связаться с ними, а это в свою очередь требует времени и денег. И даже в этом случае нет гарантии, что у продавца есть нужный персонажу предмет.</p>
          <p>Поиск магических предметов занимает минимум одну рабочую неделю и требует минимум <Price price={10000}/> на расходы. Увеличение затрат времени и золота повышает шанс найти более редкий предмет.</p>
          <p>Персонаж, который хочет купить волшебный предмет, должен совершить проверку Харизмы (Убеждение), чтобы найти продавца. Персонаж получает бонус к проверке +1 за каждую потраченную неделю после первой, и +1 за каждые дополнительно потраченные <Price price={10000}/>, вплоть до максимального значения бонуса +10. Потраченные деньги включают в себя цену богатого образа жизни, поскольку покупатель должен произвести впечатление на потенциальных деловых партнёров.</p>
          <MagicItemBuyForm />

          {
              showResult
                ? (
                  <section className='DowntimeBuyMagicItems_result'>
                      <h2>Результат проверки</h2>
                      <p>{roll} <i>(к20)</i> {charismaBonusText} <i>(Бонус харизмы)</i> + {bonusFixed} <i>(Бонусы от затрат времени и золота)</i> = <b>{formatBonus(checkResult)}</b></p>

                      <MagicItemBuyList/>
                  </section>
                )
                : null
          }
      </section>
    )
}

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    bonusFixed: state.bonusFixed,
    charismaBonus: state.charismaBonus,
    checkResult: state.checkResult,
    roll: state.roll,
    showResult: state.showResult,
  })
)(DowntimeBuyMagicItemsComponent)
