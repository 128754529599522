import random from 'lodash/random'

import {
  MGC_RARITY_COMMON,
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
  MGC_RARITY_ARTIFACT,
} from '@/constants/magicItemRarityList'

const generatePrice = (rarity, isConsumable) => {
  const price = {
    [MGC_RARITY_COMMON]: () => ((random(1, 6) + 1) * 10),
    [MGC_RARITY_UNCOMMON]: () => ((random(1, 6) + 1) * 100),
    [MGC_RARITY_RARE]: () => ((random(1, 10) + random(1, 10) + 1) * 1000),
    [MGC_RARITY_VERY_RARE]: () => ((random(1, 4) + 1) * 10000),
    [MGC_RARITY_LEGENDARY]: () => ((random(1, 6) + random(1, 6) + 1) * 25000),
    [MGC_RARITY_ARTIFACT]: () => Infinity,
  }[rarity]()

  return isConsumable
    ? price / 2
    : price
}

export default generatePrice
