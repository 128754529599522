import { SET_BUY_RESULT } from '@/components/DowntimeBuyMagicItems/store/action-types'

export default (
  {
    bonusFixed,
    checkResult,
    magicItemInfoList,
    roll,
    showResult,
  }
) => ({
  type: SET_BUY_RESULT,
  bonusFixed,
  checkResult,
  magicItemInfoList,
  roll,
  showResult,
})
