import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import formatBonus from '@/utils/formatBonus'
import rollD20 from '@/utils/rollD20'
import trackAnalyticEvent from '@/utils/trackAnalyticEvent'

import action_bonusUpdate from '@/components/DowntimeBuyMagicItems/store/actions/bonusUpdate'
import action_buyResultSet from '@/components/DowntimeBuyMagicItems/store/actions/buyResultSet'
import action_charismaBonusSet from '@/components/DowntimeBuyMagicItems/store/actions/charismaBonusSet'
import action_goldSet from '@/components/DowntimeBuyMagicItems/store/actions/goldSet'
import action_weeksSet from '@/components/DowntimeBuyMagicItems/store/actions/weeksSet'

import generateMagicItems from '@/components/DowntimeBuyMagicItems/utils/generateMagicItems'

import MagicItemBuyFormComponent from './MagicItemBuyFormComponent'

class MagicItemBuyFormContainer extends Component {
  generate = (e) => {
    e.preventDefault()

    const {buyResultSet, bonus, charismaBonus} = this.props

    const roll = rollD20() + 1
    const checkResult = roll + charismaBonus + bonus

    const magicItemInfoList = generateMagicItems(checkResult)

    buyResultSet({
      bonusFixed: bonus,
      checkResult,
      magicItemInfoList,
      roll,
    })

    trackAnalyticEvent('downtime-buy-magic-items')
  }

  onChangeGold = ({target: {value}}) => {
    const {bonusUpdate, goldSet} = this.props

    goldSet(Number(value))
    bonusUpdate()
  }

  onChangeWeeks = ({target: {value}}) => {
    const {bonusUpdate, weeksSet} = this.props

    weeksSet(Number(value))
    bonusUpdate()
  }

  onChangeCharismaBonus = ({target: {value}}) => {
    const {bonusUpdate, charismaBonusSet} = this.props

    charismaBonusSet(Number(value))
    bonusUpdate()
  }

  render() {
    const {
      bonus,
      charismaBonus,
      gold,
      weeks,
    } = this.props
    const charismaBonusText = formatBonus(charismaBonus)

    return (
      <MagicItemBuyFormComponent
        bonus={bonus}
        charismaBonus={charismaBonus}
        charismaBonusText={charismaBonusText}
        generate={this.generate}
        gold={gold}
        onChangeCharismaBonus={this.onChangeCharismaBonus}
        onChangeGold={this.onChangeGold}
        onChangeWeeks={this.onChangeWeeks}
        weeks={weeks}
      />
    )
  }
}

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    bonus: state.bonus,
    charismaBonus: state.charismaBonus,
    gold: state.gold,
    weeks: state.weeks,
  }),
  (dispatch) => ({
    dispatch,
    ...bindActionCreators(
      {
        bonusUpdate: action_bonusUpdate,
        buyResultSet: action_buyResultSet,
        charismaBonusSet: action_charismaBonusSet,
        goldSet: action_goldSet,
        weeksSet: action_weeksSet,
      },
      dispatch,
    ),
  })
)(MagicItemBuyFormContainer)
